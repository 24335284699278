
.EnterBtn_boxVisible__1iwF_ {
    text-align: center;
    border: 3px solid #FFFFFF;
    border-radius: 15px;
    height: 130px;
    width: 285px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    -webkit-animation: EnterBtn_flickerBox__33gO5 1.5s infinite alternate;
    animation: EnterBtn_flickerBox__33gO5 1.5s infinite alternate;
}

.EnterBtn_boxHidden__3XyJs {
    text-align: center;
    border: 3px solid #FFFFFF;
    border-radius: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    -webkit-animation: EnterBtn_flickerBox__33gO5 1.5s infinite alternate;
    animation: EnterBtn_flickerBox__33gO5 1.5s infinite alternate;
    width: 285px;
    height: 130px;
    opacity: 0;
    transition: opacity .5s;
    cursor: auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
}


.EnterBtn_text__1o2MF {
    font-size: 36px;
    font-family: "Good Times";
    font-weight: 400;
    color: #FFFFFF;
    line-height: 130px;
    -webkit-animation: EnterBtn_flickerText__3srJn 1.5s infinite alternate;
    animation: EnterBtn_flickerText__3srJn 1.5s infinite alternate;
}


@-webkit-keyframes EnterBtn_flickerBox__33gO5 {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {

        box-shadow:
            0 0 2.5px #FFFFFF,
            inset 0 0 2.5px #FFFFFF,
            0 0 10px #0ABDC6,
            inset 0 0 10px #0ABDC6,
            0 0 20px #0ABDC6,
            inset 0 0 20px #0ABDC6;
    }

    20%,
    24%,
    55% {
        box-shadow: none;
    }
}


@keyframes EnterBtn_flickerBox__33gO5 {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {

        box-shadow:
            0 0 2.5px #FFFFFF,
            inset 0 0 2.5px #FFFFFF,
            0 0 10px #0ABDC6,
            inset 0 0 10px #0ABDC6,
            0 0 20px #0ABDC6,
            inset 0 0 20px #0ABDC6;
    }

    20%,
    24%,
    55% {
        box-shadow: none;
    }
}


@-webkit-keyframes EnterBtn_flickerText__3srJn {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
        text-shadow:
            -1px -1px 3px #FFFFFF,
            1px 1px 3px #FFFFFF,
            0 0 5px #EA00D9,
            0 0 10px #EA00D9,
            0 0 15px #EA00D9,
            0 0 20px #EA00D9,
            0 0 25px #EA00D9;
    }

    20%,
    24%,
    55% {
        text-shadow: none;
    }
}

@keyframes EnterBtn_flickerText__3srJn {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
        text-shadow:
            -1px -1px 3px #FFFFFF,
            1px 1px 3px #FFFFFF,
            0 0 5px #EA00D9,
            0 0 10px #EA00D9,
            0 0 15px #EA00D9,
            0 0 20px #EA00D9,
            0 0 25px #EA00D9;
    }

    20%,
    24%,
    55% {
        text-shadow: none;
    }
}
.Home_containerHidden__1Ojfj {
  opacity: 0;
}

.Home_containerVisible__1UYt9 {
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: opacity 1.4s;
  transition-timing-function: ease-in;
}
.NotFound_box__15fIv {
    text-align: center;
    border: 3px solid #FFFFFF;
    border-radius: 15px;
    height: 130px;
    width: 285px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-animation: NotFound_flickerBox__IIgD_ 1.5s infinite alternate;
    animation: NotFound_flickerBox__IIgD_ 1.5s infinite alternate;
}

.NotFound_text1__1iYyI {
    font-family: "Good Times";
    font-size: 36px;
    font-weight: 400;
    color: #FFFFFF;
    padding-top: 27px;
    -webkit-animation: NotFound_flickerText__1uvGd 1.5s infinite alternate;
    animation: NotFound_flickerText__1uvGd 1.5s infinite alternate;
}

.NotFound_text2__1eWhU {
    font-family: "Good Times";
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    padding-top: 5px;
    -webkit-animation: NotFound_flickerText__1uvGd 1.5s infinite alternate;
    animation: NotFound_flickerText__1uvGd 1.5s infinite alternate;

}

@-webkit-keyframes NotFound_flickerBox__IIgD_ {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {

        box-shadow:
            0 0 2.5px #FFFFFF,
            inset 0 0 2.5px #FFFFFF,
            0 0 10px #0ABDC6,
            inset 0 0 10px #0ABDC6,
            0 0 20px #0ABDC6,
            inset 0 0 20px #0ABDC6;
    }

    20%,
    24%,
    55% {
        box-shadow: none;
    }
}


@keyframes NotFound_flickerBox__IIgD_ {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {

        box-shadow:
            0 0 2.5px #FFFFFF,
            inset 0 0 2.5px #FFFFFF,
            0 0 10px #0ABDC6,
            inset 0 0 10px #0ABDC6,
            0 0 20px #0ABDC6,
            inset 0 0 20px #0ABDC6;
    }

    20%,
    24%,
    55% {
        box-shadow: none;
    }
}


@-webkit-keyframes NotFound_flickerText__1uvGd {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
        text-shadow:
            -1px -1px 3px #FFFFFF,
            1px 1px 3px #FFFFFF,
            0 0 5px #EA00D9,
            0 0 10px #EA00D9,
            0 0 15px #EA00D9,
            0 0 20px #EA00D9,
            0 0 25px #EA00D9;
    }

    20%,
    24%,
    55% {
        text-shadow: none;
    }
}

@keyframes NotFound_flickerText__1uvGd {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
        text-shadow:
            -1px -1px 3px #FFFFFF,
            1px 1px 3px #FFFFFF,
            0 0 5px #EA00D9,
            0 0 10px #EA00D9,
            0 0 15px #EA00D9,
            0 0 20px #EA00D9,
            0 0 25px #EA00D9;
    }

    20%,
    24%,
    55% {
        text-shadow: none;
    }
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: fixed;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a, a:active, a:focus {
   outline: none;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(/static/media/OpenSans-Regular.192fd6b1.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(/static/media/OpenSans-Bold.e4452df6.woff) format('woff');
}

@font-face {
  font-family: 'Good Times';
  font-style: normal;
  font-weight: 400;
  src: local('Good Times Regular'), local('GoodTimes-Regular'), url(/static/media/GoodTimes-Regular.54faeb51.woff) format('woff');
}
