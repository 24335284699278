body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: fixed;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a, a:active, a:focus {
   outline: none;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./assets/fonts/OpenSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(./assets/fonts/OpenSans-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Good Times';
  font-style: normal;
  font-weight: 400;
  src: local('Good Times Regular'), local('GoodTimes-Regular'), url(./assets/fonts/GoodTimes-Regular.woff) format('woff');
}